import React from "react"
import AlbertImage from "../../../static/assets/about/albert-einstein.png"

function Vision() {
  return (
    <div className="vision-container">
      <div className="vision-discription-container">
        <div className="vision-header">OUR VISION</div>
        <div className="vision-summary">
          Fostering a culture of hard work, persistence and excellence
        </div>
        <div className="vision-text-container">
          <div className="vision-text">
            Our team is our biggest asset, and we like challenging ourselves.
            Keyla was created to provide our people a platform where they are
            free to think critically and innovate, to create a better world.
          </div>
          <div className="vision-text">
            As revolutionaries, we are continuously striving to learn new things
            and experimenting with technology to make them better.
          </div>
          <div className="vision-text">
            We use our expertise and experience to deliver the best digital
            solutions in the industry, enabling businesses to scale and grow.
            And we take pride in creating technology driven solutions that
            empower people and change lives.
          </div>
          <div className="vision-text">
            That’s what we’re really good at and that’s what really matters to
            us.
          </div>
        </div>
      </div>
      <div className="vision-quote-container">
        <div className="vision-quote-header">Stay Motivated</div>
        <img
          src={AlbertImage}
          alt="Albert Einstein"
          style={{
            width: "100%",
            maxWidth: "346.64px",
          }}
        />
        <div className="vision-quote-content-container">
          <div className="vision-quote-mark">“</div>
          <div className="vision-quote-content">
            Imagination is more important than knowledge. Knowledge is limited.
            Imagination encircles the world.
          </div>
        </div>
        <div className="vision-quote-author">Albert Einstein.</div>
      </div>
    </div>
  )
}

export default Vision
