import React from "react"

function VideoSection() {
  return (
    <div
      style={{
        marginTop: 100,
        paddingInline: "9vw",
        width: "100%",
      }}
    >
      <iframe
        width="100%"
        height="506"
        src="https://www.youtube.com/embed/-fI0IGt4gwE"
        title="Keyla Technologies"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        style={{
          borderRadius: 10,
        }}
      ></iframe>
    </div>
  )
}

export default VideoSection
