import { Link } from "gatsby"
import React from "react"
import "./about.css"

const Hero = () => {
  return (
    <div className="about-hero-container">
      <span className="about-hero-text">
        About{" "}
        <span
          className="about-hero-text"
          style={{
            color: "#2D9CDB",
          }}
        >
          Keyla
        </span>
      </span>
      <h1 className="about-hero-title">
        We specialize in building lasting digital solutions
      </h1>
      <h2 className="about-hero-subtitle">
        Building world class digital experience that initiate growth and
        conversion for enterprise level businesses
      </h2>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <Link
          to="/services#service-page-inquiry"
          style={{
            textDecoration: "none",
          }}
        >
          <button className="about-hero-button">Get Started</button>
        </Link>
      </div>
    </div>
  )
}

export default Hero
