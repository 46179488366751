import { Link } from "gatsby"
import React from "react"

function HomeAction() {
  return (
    <div className="home-action-container">
      <div className="home-action-content-container">
        <h5 className="home-action-header">
          Ready to start with{" "}
          <span
            className="home-action-header"
            style={{
              color: "#2D9CDB",
            }}
          >
            Us?
          </span>
        </h5>
        <span className="home-action-content">
          Let’s build something together! Enjoy the luxury of working with us or
          contact us below.
        </span>

        <Link
          to="/services#service-page-inquiry"
          style={{
            textDecoration: "none",
          }}
        >
          <button className="home-action-button">Get in touch</button>
        </Link>
      </div>
    </div>
  )
}

export default HomeAction
