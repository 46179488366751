import React from "react"
import { Layout } from "../components/global"
import Hero from "../components/About/Hero"
import Mission from "../components/About/Mission"
import Vision from "../components/About/Vision"
import HomeAction from "../components/Home/HomeAction"
import VideoSection from "../components/About/VideoSection"
import { Helmet } from "react-helmet"

const about = () => {
  return (
    <Layout>
      <Helmet>
        <title>Keyla Technologies - About</title>
      </Helmet>
      <Hero />
      <Mission />
      <Vision />
      <VideoSection />
      <HomeAction />
    </Layout>
  )
}

export default about
