import React from "react"
import "./about.css"

function Mission() {
  return (
    <div className="mission-container">
      <div className="mission-summary-container">
        <div className="mission-summary">
          Relentless approach to creating exceptional digital experience
        </div>
        <div className="mission-description">
          <h2 className="mission-header">OUR MISSION</h2>
          <div className="mission-content-container">
            <span className="mission-text">
              Our relentless pursuit of being the best digital transformation
              company has enabled us to become industry leaders in the digital
              world by actively adding value to our partners’ projects.
            </span>
            <span span className="mission-text-emphasized">
              We constantly learn, ideate, process, iterate and scale. At keyla,
              we consider clients our partners, and your success is our success.
            </span>
            <span span className="mission-text">
              This approach enables us to deliver effective digital solutions
              that empower businesses and allow them to scale. We create
              unparalleled technological experiences for their clients and help
              them stay ahead of the digital curve.
            </span>
          </div>
        </div>
      </div>
      <div className="mission-list-container">
        <div className="mission-list-item">Learn.</div>
        <div className="mission-list-item">Ideate.</div>
        <div className="mission-list-item">Process.</div>
        <div className="mission-list-item">Iterate.</div>
        <div className="mission-list-item">Scale.</div>
      </div>
    </div>
  )
}

export default Mission
